import React, { useEffect, useState } from "react";
import classNames from "classnames";
import ReactPlayer from "react-player";

import { Image } from "../../types";

import s from "./CardVideo.module.scss";

export interface CardVideoProps {
    videoUrl?: string;
    image: Image;
    autoplay?: boolean;
}

const NATIVE_VIDEO_FORMATS = [".mp4", ".webm"];

declare let cookieTractor: {
    consent: {
        add: (category: string) => void;
    };
};

const CardVideo: React.FC<CardVideoProps> = ({
    videoUrl,
    image = {},
    autoplay = true,
}) => {
    const [mounted, setMounted] = useState(false);
    const [marketingCookie, setMarketingCookie] = useState(false);
    const imageRendition = image.renditions?.medium;
    const useNativeVideoPlayback =
        typeof videoUrl !== "undefined"
            ? NATIVE_VIDEO_FORMATS.map((format) =>
                  videoUrl.endsWith(format)
              ).filter((v) => !!v).length > 0
            : false;

    useEffect(() => {
        window.addEventListener(
            "CookieConsentGiven",
            function (e: Event) {
                setMounted(true);

                let allowMarketing =
                    (e as any).detail.consents.indexOf("marketing") > -1;

                allowMarketing
                    ? setMarketingCookie(true)
                    : setMarketingCookie(false);
            },
            false
        );

        window.addEventListener(
            "CookieConsentRevoked",
            function (e: Event) {
                let revokedMarketing =
                    (e as any).detail.consents.indexOf("marketing") > -1;

                if (revokedMarketing) {
                    setMarketingCookie(false);
                }
            },
            false
        );
    }, []);

    const handleClick = () => {
        cookieTractor.consent.add("marketing");
    };

    return (
        <>
            {mounted && imageRendition && (
                <img
                    className={s.FallbackImage}
                    src={imageRendition.src}
                    loading="lazy"
                    alt={imageRendition.alt}
                />
            )}

            {/* native player has to be used when playing video files directly since reactplayer
            does not correctly pass on the autoPlay prop to the underlying video-element */}

            {useNativeVideoPlayback && (
                <div className={s.NativePlayerWrapper}>
                    <video
                        src={videoUrl}
                        tabIndex={-1}
                        {...(autoplay
                            ? {
                                  playsInline: true,
                                  autoPlay: true,
                                  muted: true,
                                  loop: true,
                                  controls: false,
                              }
                            : {
                                  playsInline: false,
                                  autoPlay: false,
                                  muted: false,
                                  loop: false,
                                  controls: false,
                              })}
                    />
                </div>
            )}

            {!useNativeVideoPlayback && (
                <div className={s.ExternalPlayerWrapper}>
                    {mounted &&
                        (marketingCookie ? (
                            <ReactPlayer
                                className={s.Player}
                                width="100%"
                                height="100%"
                                url={videoUrl}
                                {...(autoplay
                                    ? {
                                          loop: true,
                                          muted: true,
                                          playsinline: true,
                                          playing: true,
                                          controls: false,
                                      }
                                    : {
                                          loop: false,
                                          muted: false,
                                          playsinline: false,
                                          playing: false,
                                          controls: true,
                                      })}
                            />
                        ) : (
                            <div className={s.ConsentOverlay}>
                                <p>
                                    För att spela videon behöver du acceptera
                                    cookies för marknadsföring.
                                </p>
                                <button
                                    className={classNames(
                                        s.ConsentButton,
                                        "cookie-consent-settings"
                                    )}
                                    onClick={handleClick}
                                >
                                    Acceptera marknadsföringscookies
                                </button>
                            </div>
                        ))}
                </div>
            )}
        </>
    );
};

export default CardVideo;
